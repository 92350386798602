import React from 'react'
import { useDataContext } from 'src/contexts/dataContext'
import { createGlobalStyle } from 'styled-components'

// interface GlobalStyleProps {
//   isMenuOpen: boolean
// }
const GlobalStyle = createGlobalStyle`
html {
  scroll-behavior: smooth;
}
`
const GlobalStyle1 = createGlobalStyle`
html {
  scroll-behavior: smooth;

}
body{ overflow: hidden;}
`

export const GlobalStyleContainer = () => {
  const { showMenu } = useDataContext()

  return !showMenu ? <GlobalStyle /> : <GlobalStyle1 />
}
