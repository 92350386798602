import React, { ReactNode, createContext, useContext, useState } from 'react'
import { data, dataInglese, Data, menu, Menu } from 'src/contenuto/data'

interface DataContextProviderProps {
  children: ReactNode
}

interface DataContextValue {
  getAllData: () => Data
  getAllMenu: () => Menu
  toggleMenu: () => void
  toggleItalian: () => void
  toggleEnglish: () => void
  showMenu: boolean
  language: string
}
const DEFAULT_CONTEXT_VALUE = {
  getAllData: () => {
    return {} as Data
  },
  getAllMenu: () => {
    return {} as Menu
  },
  toggleMenu: () => undefined,
  toggleItalian: () => undefined,
  toggleEnglish: () => undefined,
  showMenu: false,
  language: 'ITA'
}

const MyDataContext = createContext<DataContextValue>(DEFAULT_CONTEXT_VALUE)

export const useDataContext = () => useContext(MyDataContext)

export const MyDataContextProvider = ({
  children
}: DataContextProviderProps) => {
  const [language, setLanguage] = useState('ITA')
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => setShowMenu(curr => !curr)
  const getAllData = () => (language === 'ITA' ? data : dataInglese)
  const getAllMenu = () => menu
  const toggleItalian = () => {
    setLanguage('ITA')
  }
  const toggleEnglish = () => setLanguage('EN')

  return (
    <>
      {data && menu && (
        <MyDataContext.Provider
          value={{
            showMenu,
            toggleMenu,
            getAllMenu,
            getAllData,
            toggleItalian,
            toggleEnglish,
            language
          }}
        >
          {children}
        </MyDataContext.Provider>
      )}
    </>
  )
}
