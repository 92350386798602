import React from 'react'

import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { MyDataContextProvider } from './src/contexts/dataContext'
import theme from './src/styles/theme'
import { GlobalStyleContainer } from './src/components/GlobalStyleContainer.tsx'

export const wrapRootElement = ({ element }) => (
  <>
    <MyDataContextProvider>
      <GlobalStyleContainer />
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </MyDataContextProvider>
  </>
)
