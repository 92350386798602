export interface Menu {
  giorno: string
  antipasto: string[]
  primi: string[]
  secondi: string[]
  dolci: string[]
  bevande: string[]
  prezzo: string[]
}

export const menu: Menu = {
  giorno: 'Menu',
  antipasto: [
    'Vellutata di fave secche e cicoria selvatica, Insalatina di radicchio, Polenta al ragù, Involtino di verza,Cous cous alla zucca'
  ],
  primi: [
    'Tortelli al radicchio e noci',
    'Passatelli con topinambur broccoli e salsiccia'
  ],
  secondi: ['Coniglio al finocchietto selvatico', 'Brasato di capocollo'],
  dolci: ['Semifreddo al torroncino', 'Cheesecake'],
  bevande: [
    "Colli Pesaresi o Bianchello del Metauro della cantina Bruscia, caffe' moka, digestivi fatti in casa."
  ],
  prezzo: [
    "Menu' completo a 30,00 euro",
    "Menu' 2 portate (antipasti e primi oppure antipasti e secondi) compreso dolce e bevande 24,00/25,00 euro",
    "Menu' due portate primi e secondi compreso dolce e bevande euro 26,00",
    "Menu' antipasto con bevande e dolce 18,00/20,00 euro",
    "Menu' cerimonie da 30,00 euro"
  ]
}

export interface Data {
  agriturismo: {
    presentazione: string
    titolo: string
    frase: string
    contenuto: string
  }
  agricoltura: {
    presentazione: string
    titolo: string
    frase: string
    contenuto: string
  }
  cucina: {
    presentazione: string
    titolo: string
    frase: string
    contenuto: string
  }
  camere: {
    presentazione: string
    titolo: string
    frase: string
    contenuto: string
  }
}

export const data: Data = {
  agriturismo: {
    presentazione: 'Un agriturismo dove sentirsi a casa, veramente a casa.',
    titolo: "L'agriturismo",
    frase: 'Una scelta di vita',
    contenuto:
      "Sono oramai tredici anni che abbiamo intrapreso questa avventura e dieci anni che abbiamo l'onore di ospitarvi. Per descriverci prendiamo in prestito alcune parole dai vostri commenti e dalle vostre recensioni: calore, poesia, accoglienza, semplicità, qualità, serenità, casa, famiglia, atmosfera, piacevole, squisito, gustoso, cordiale, simpatico, km 0, positivo, anticonvenzionale, rustico, delicato, tradizione e innovazione."
  },

  agricoltura: {
    presentazione: 'Agricoltura sostenibile, terreno fertile, ortaggi sani.',
    titolo: "L'orto",
    frase: 'Tanta fatica... tante soddisfazioni.',
    contenuto:
      "Tutti i lavori dell'orto sono fatti nel rispetto della Natura. Seguiamo il suo corso, la sua stagionalità; proviamo ad aiutarla con letame, paciamatura e macerati, ma non stravolgiamo il suo lavoro. Lei sa cosa e come farlo. Niente di più."
  },
  cucina: {
    presentazione: 'A volte più che descrivere è meglio provare.',
    titolo: 'La cucina',
    frase:
      "Come diciamo sempre: un po' di tradizione un po'... diverso dal solito.",
    contenuto:
      'Materia prima buona, quasi sempre biologica, proveniente dalla nostra azienda e dalle altre aziende agricole che ci supportano,abbinamenti semplici con il giusto condimento per gustare i sapori e per finire,il palato di chi crea gli abbinamenti,questo è il segreto della nostra cucina.Tutto questo troverete nel nostro menù degustazione.'
  },
  camere: {
    presentazione: 'La tranquillità di un casale rustico, semplice.',
    titolo: 'Le camere',
    frase: 'Tutto intorno, il Montefeltro',
    contenuto:
      "Un po' di vento, un assiolo, una civetta, qualche grillo, tantissime lucciole, i “rumori” della natura, il cielo stellato, la pace, non c'è niente di meglio per pensare, per parlare, per acquietarsi, per dormire. Un caffè moka, qualche dolcetto fatto in casa, una chiacchiera per arricchirsi. Ricomincia la giornata, al lavoro, in vacanza, non importa, va già meglio."
  }
}

export const dataInglese: Data = {
  agriturismo: {
    presentazione: 'A farmhouse where you can feel at home, truly at home.',
    titolo: 'The Farmhouse',
    frase: 'A choice of life',
    contenuto:
      'It is now thirteen years that we have undertaken this adventure and ten years that we have the honor of hosting you. To describe ourselves we borrow a few words from your comments and reviews: warmth, poetry, hospitality, simplicity, quality, serenity, home, family, atmosphere, pleasant, exquisite, tasty, friendly, nice, km 0, positive, unconventional, rustic, delicate, tradition and innovation.'
  },

  agricoltura: {
    presentazione: 'Sustainable agriculture, fertile soil, healthy vegetables.',
    titolo: 'The vegetable garden',
    frase: 'So much effort ... so much satisfaction.',
    contenuto:
      "All the work in the garden is done in respect of nature. We follow its course, its seasonality; let's try to help her with manure, mulch and macerates, but let's not upset her work. You know what and how to do it. Nothing more."
  },
  cucina: {
    presentazione: 'Sometimes it is better to try rather than describe.',
    titolo: 'The kitchen',
    frase:
      'As we always say: a bit of tradition a bit ... different from usual.',
    contenuto:
      'Good raw material, almost always organic, coming from our company and from other farms that support us, simple combinations with the right seasoning to taste the flavors and finally, the palate of those who create the combinations, this is the secret of our cuisine You will find all this in our tasting menu.'
  },
  camere: {
    presentazione: 'The tranquility of a rustic, simple farmhouse.',
    titolo: 'Rooms',
    frase: 'All around, the Montefeltro',
    contenuto:
      "A little wind, a scops owl, an owl, some crickets, lots of fireflies, the 'noises' of nature, the starry sky, peace, there is nothing better to think, to talk, to calm down, to sleep . A mocha coffee, some homemade sweets, a chat to get rich. Start the day again, at work, on vacation, it doesn't matter, it's already better."
  }
}
